export const general = { 
    /* Navigation Menu */
    nav_Link1: "Home Page",
    nav_Link2: "Applications",
    nav_Link3: "Contact us",
    /* End of Navigation Menu */
    home_title: "Developers and Designers for the modern enterprise!",
    home_description: "We develop web and mobile applications to help you achieve the goals of your company. We have already developed a custom e-shop and a food delivery app named BringFood that you can buy using up to 24 installments. In general our apps are very fast and we can guarantee their immaculate operation. They can be altered to suit your special needs or you can construct a totally new app!", 
    home_products: "Find out about our products",
    home_steps: "Lets talk!",
    home_teazer: "What exactly do you want to do with your app?",
    home_call: "Call us",
    home_contact: "Contact us",
    address: "Address",
    addressof: "71-75, Shelton street, Covent Garden, London",
    phone: "tel.: 02081596097",
    Terms: "Terms - Policy - Cookies",
    our_goal: "Duceroad was created by a group of developers with the same philosophy and the same vision: love for technology, respect for the customer and upgrading the Greek market with innovative technological ideas.",
    apps_header_title: "App Directory",
    apps_header_subtitle: "Offering Solutions to all types of applications",
    sub : "Subcategories depth 5",
    mob_responsive: "Mobile responsive",
    apps: "Applications",
    push_notifications: "Push notifications",
    var_features: "Variations + Features",
    search: "Smart search",
    priceA: "Price: From 850£",
    same: "Same features with web app",
    dashboard: "Common dashboard of all apps",
    priceB: "Price: From 1000£",
    booking_system: "Direct Bookings",
    contact_form: "Contact Form",
    booking_sychronize: "Availability sychronize",
    cat: "Categories first page",
    priceC: "Price: 8.000£",
    priceD: "Price including web app 10.000£",
    priceE: "From 100£ / month",
    priceF: "From 150£ / month",
    priceG: "Price: from 5.000£",
    fast: "Lightning fast speed. User friendly dashboard.",
    watch: "Watch",
    addMobile: "Add the mobile apps for AppStore and PlayStore too.",
    attract: "Attract customers",
    customers: "Create new customer base through registration.",
    loading: "Instant loading",
    instant: "Instant page loading due to latest technologies used.",
    increase: "Increase your turnover",
    takeAdvantage: "Take advantage of click away and online ordering.",
    adaptability: "Great adaptability",
    enterProducts: "Enter products from the dashboard. It is included in the price",
    domain: "Use your own custom domain or subdomain.",
    filters: "Adaptable search and filters",
    catSearch: "Search with category or product name.",
    analytics: "Monitor clicks in eshop.",
    security: "Great security",
    ssl: "HTTPS and hidden password. Custom site.",
    pricing: "Affordable pricing.",
    priceDetail: "5.000£ for the web app and dashboard. Additional 2.000£ for the mobile apps.",
    inTheShopPriceDetail: "8.000£ for the web app and dashboard. Additional 2.000£ for the mobile apps.",
    fin: "Easy financing",
    gov: "Up to 24 installments.",
    presentation: "Watch the presentation",
    presDetail: "Send us your email to watch the presentation.",
    priceH: "Starting at 100£ / month",
    reviews: "Product Reviews",
    reviewsDet: "Connect with your customers",
    connect: "Connection with ERP",
    connectDet: "Improve your throughput",
    /* eshop WooCommerce Page */
    eshop_Heading_Title: "Get your ideas online with a real Woo-Commerce eshop!",
    eshop_Heading_Paragraph: "Start selling from the first day of launch!",
    eshop_Heading_Paragraph_2: "Product Price: From 850£",
    eshop_Title_1: "Customizable Storefront",
    eshop_Heading_Paragraph_1_1: "Wide range of themes",
    eshop_Paragraph_1_1: "We can offer a wide range of themes that can be customized to match the look and feel of your online store. You can choose from a variety of premium themes to make your store stand out and provide a seamless shopping experience to your customers.",
    eshop_Heading_Paragraph_1_2: "Customizable features",
    eshop_Paragraph_1_2: "Also we can allow you to customize various features of your store to fit your business needs. From product pages to checkout pages, you can make your store your own with custom branding, layouts, and more.",
    eshop_Title_2: "Secure Payment Options",
    eshop_Heading_Paragraph_2_1: "Accept payments easily",
    eshop_Paragraph_2_1: "Connect with many payment gateways as Pireus Bank, Eurobank, etc. , making it easy for you to accept payments from customers around the world. Customers can choose from a variety of secure options, including credit and debit cards, PayPal, and more.",
    eshop_Heading_Paragraph_2_2: "Protect sensitive information",
    eshop_Paragraph_2_2: "Your customer's sensitive information is protected by WooCommerce's secure payment systems. The platform uses industry-standard encryption to keep customer information safe and secure, giving your customers peace of mind when shopping on your site.",
    eshop_Title_3: "Easy Product Uploads",
    eshop_Heading_Paragraph_3_1: "Upload Products with Ease",
    eshop_Paragraph_3_1: "Easily upload products, manage product details, and update inventory levels. The platform makes it simple to add new products, change product information, and manage your product catalog.",
    eshop_Heading_Paragraph_3_2: "Synchronize with CSV files from Suppliers",
    eshop_Paragraph_3_2: "Import products from suppliers using CSV files. You can easily synchronize your product catalog with supplier information and keep your inventory levels up-to-date. This saves you time and ensures accuracy in your product information.",
    eshop_Title_4: "Flexible Shipping Options",
    eshop_Heading_Paragraph_4_1: "Customizable Shipping Rates",
    eshop_Paragraph_4_1: "Offer customizable shipping rates to your customers. Set flat rates, weight-based rates, or even create complex rules for shipping costs based on product, location, and more. This gives you the flexibility to offer shipping options that meet the needs of your business and your customers.",
    eshop_Heading_Paragraph_4_2: "Real-time Shipping Quotes",
    eshop_Paragraph_4_2: "Integrate with leading shipping carriers, allowing you to provide real-time shipping quotes to your customers during the checkout process. This helps customers make informed decisions about their purchases and increases the likelihood of completing a sale. With the ability to offer multiple shipping options and real-time quotes, you can provide an excellent shipping experience for your customers.",
    eshop_Title_5: "Integration with Price Comparison Platforms and ERP Systems",
    eshop_Heading_Paragraph_5_1: "Synchronization with Skroutz / BestPrice",
    eshop_Paragraph_5_1: "Your eshop can integrate with popular price comparison platforms such as Skroutz or BestPrice, allowing you to easily synchronize your product information and prices with Skroutz. This helps increase visibility for your products and can drive more sales to your online store.",
    eshop_Heading_Paragraph_5_2: "Improved Efficiency and Accuracy with ERP Integration",
    eshop_Paragraph_5_2: "Connect with ERP systems such as SoftOne improves efficiency and accuracy in your business operations. You can easily manage your products, inventory, and sales from one central location, reducing manual errors and saving you time.",
    eshop_Title_6: "Expected and extra benefits",
    eshop_Heading_Paragraph_6_1: "Ability to receive orders",
    eshop_Paragraph_6_1: "The program allows easy creation of an order by the end customer and easy reading by the administrator, keeping billing and shipping details, purchase products and total money.",
    eshop_Heading_Paragraph_6_2: "Extra benefits with 2nd language support and B2B eshop",
    eshop_Paragraph_6_2: "Enable multilingualism or even B2B (Selling to professionals) in your online store",
    eshop_Project_Heading: "Projects made with WooCommerce",
    eshop_Project_Paragraph: "No words to be said, only project talk..",
    eshop_Project_Button: "Iam interested!",
    eshop_section_Title: "Price Catalogue",
    eshop_section_Paragraph_1: "Design and implementation with the Wordpress / WooCommerce platform",
    eshop_section_Paragraph_2:"Ideally easy to use in the subsequent management for both beginners and experienced users",
    /*
    End of eshop WooCommerce Page
     -----------
    /* Booking system Page */
    booking_Heading_Title: "Get your ideas online with a real booking site",
    booking_Heading_Paragraph: "Package which covers from the smallest rental apartment up to the most luxury hotel in town!",
    booking_Heading_Paragraph_2: "Product Price: From 1000£",
    booking_Title_1: "Stand Out with a Unique and Professional Website",
    booking_Heading_Paragraph_1_1: "Customizable Design to Match Your Brand",
    booking_Paragraph_1_1: "Our WordPress-based websites can be fully customized to meet your needs and preferences. You can choose from a variety of themes, colors, and layouts to create a unique and professional online presence that matches your brand and style.",
    booking_Heading_Paragraph_1_2: "Improved User Experience and Engagement",
    booking_Paragraph_1_2: "A well-designed website can improve user experience and engagement, resulting in more bookings and revenue. Our team of experts can help you design a website that is not only visually appealing but also user-friendly and optimized for conversion.",
    booking_Title_2: "Secure Payment Options",
    booking_Heading_Paragraph_2_1: "Accept payments easily",
    booking_Paragraph_2_1: "Connect with many payment gateways as Pireus Bank, Eurobank, etc. , making it easy for you to accept payments from customers around the world. Customers can choose from a variety of secure options, including credit and debit cards, PayPal, and more.",
    booking_Heading_Paragraph_2_2: "Fully Secure Payment System",
    booking_Paragraph_2_2: "Our payment system is fully secure and encrypted, ensuring that your customers payment information is protected at all times. This feature will give your customers peace of mind and confidence in making their reservations through your website.",
    booking_Title_3: "Booking System",
    booking_Heading_Paragraph_3_1: "Always Up-to-Date Availability",
    booking_Paragraph_3_1: "Our website solution offers real-time availability, which means that your customers can see the most up-to-date information about your property's availability. This feature ensures accurate and timely bookings, reducing the risk of overbooking and cancellations.",
    booking_Heading_Paragraph_3_2: "Streamlined Management and Administration",
    booking_Paragraph_3_2: "Our channel management feature simplifies the management and administration of your property by automatically updating your availability and rates across multiple platforms (Booking.uk / Air-BnB etc.) . ",
    booking_Title_4: "Mobile-Friendly Design for On-the-Go Bookings",
    booking_Heading_Paragraph_4_1: "Optimized for Mobile Devices",
    booking_Paragraph_4_1: "Our websites are designed to be mobile-friendly, ensuring that customers can easily book their stay on any device, including smartphones and tablets.",
    booking_Heading_Paragraph_4_2: "Enhanced User Experience and Accessibility",
    booking_Paragraph_4_2: "A mobile-friendly website improves the user experience and accessibility, making it easier for your customers to find information, make reservations, and interact with your property.",
    booking_Title_5: "Reach More Customers",
    booking_Heading_Paragraph_5_1: "Search Engine Optimization (SEO)",
    booking_Paragraph_5_1: "Our package is designed with search engine optimization (SEO) in mind, helping your property to rank higher in search results and reach more customers. This feature will help you increase your online visibility and attract more traffic to your website.",
    booking_Heading_Paragraph_5_2: "Social Media Integration",
    booking_Paragraph_5_2: "Our websites also integrate with social media, allowing you to promote your property and engage with your customers on popular social media platforms like Facebook, Instagram, and Twitter. ",
    booking_Project_Heading: "New Rental / Hotel sites",
    booking_Project_Paragraph: "No words to be said, only project talk..",
    booking_Project_Button: "Iam interested!",
    /*
    End of booking system WooCommerce Page
     -----------
    Start of Contact us Page
    */
    contact_head_title: "Contact us",
    contact_head_subtitle: "Give your info and we will get in touch!",
    contact_input_1: "Company",
    contact_input_2: "First Name",
    contact_input_3: "Last Name",
    contact_input_4: "Phone",
    contact_input_5: "Email",
    contact_input_6: "Message",
    contact_radio_header: "Iam interested in:",
    contact_custom_app: "Custom application",
    contact_submit: "Submit",
    /*
    End of Contact us Page
     -----------
    Start of General Translations
    */
    field_required: "Field is required",
    /*
    End of General Translations
     -----------
    Start of Policy
    */
    policy_title: 'Privacy and Cookie Policy & Terms',
    policy_subtitle: 'Last Updated: 7/2022',
    policy_heading: 'Policy',
    policy_paragraph: 'For Duceroad the protection of personal data is of paramount importance and is treated with the utmost seriousness. Respecting the personal data we handle and ensuring their proper processing is one of the Companys priorities. For this reason, we take all appropriate technical and organizational measures to protect the personal data we process and to ensure that their processing is always carried out in accordance with the requirements set by the applicable legal framework and, in particular, by the General Data Protection Regulation (EU) 2016/679.',
    terms_heading: 'Terms',
    terms_heading_2: '1. Controller - Contact details',
    terms_paragraph_2: 'Duceroad acts as the Controller for all personal data it collects, processes and stores.',
    terms_par_2_list_1: 'Duceroad (Duceroad Technologies)',
    terms_par_2_list_2: 'Address: 71-75, Shelton street, Covent Garden, London',
    terms_par_2_list_3: 'Telephone: 02081596097',
    terms_par_2_list_4: 'Email: info@duceroad.uk',
    terms_par_2_list_5: 'Contact Person: Christidis Iosif',
    terms_heading_3: '2. What data we collect and process',
    terms_paragraph_3: 'In order to serve you and offer you our services and products, we collect and process all the data you provide to the call center representative when you place your order or the data you fill in the corresponding fields when you open a user account on our website, and more specifically, full name, postal address, contact phone number (landline or mobile), e-mail, tax information (in case you wish to issue an invoice). Please note that our Companys website may contain links to other websites. Our Company is not responsible for the privacy and data protection practices and/or the content of these websites. If you are under the age of 15, please obtain permission from your parent or guardian before sharing any of your personal information with us.',
    terms_heading_4: '3. Processing purposes and legal bases',
    terms_paragraph_4: 'All the above personal data is collected and processed for the purpose of executing the contract between us for the sale of our products and more specifically for the readiness and delivery of your order and our communication with you about it. Some of your data can be used and for the purposes always with your own relevant consent',
    terms_par_4_list_1: 'Contacting you in connection with contests, sweepstakes and other events',
    terms_par_4_list_2: 'Updating and sending offers and informational material about our products and services',
    terms_par_4_list_3: 'Statistical purposes and surveys',
    terms_par_4_list_4: 'Promotion and promotion of our products and services',
    terms_paragraph_4_2: 'Legal bases for data processing may be as the case may be:',
    terms_par_4_2_list_1: 'The execution and completion of the contract between us for the sale of our products to you (preparation and delivery of your order). Without the collection and use of the above information it will not be possible to serve you',
    terms_par_4_2_list_2: 'The consent that you may provide to us in order to receive updates regarding offers or promotional material for our products and services, commercial actions and activities that we develop, etc. You can withdraw your consent whenever you wish, by contacting us at contact details mentioned in paragraph 1 or through our website.',
    terms_heading_5: '4. Where we share your personal data',
    terms_paragraph_5: 'Your personal data may be shared with the following recipients:',
    terms_par_5_list_1: 'To franchisees, Duceroad network store managers and distributors to prepare and deliver the order',
    terms_par_5_list_2: 'To a newsletter sending company (provided you have given your consent for us to send you a newsletter)',
    terms_par_5_list_3: 'To an SMS sending company (if you have given your consent to receive updates)',
    terms_par_5_list_4: 'To partner companies, in order to be able to receive your gift if you are a winner in a competition in which you took part (every time we organize a competition, we mention in the terms of the competition that your details have been shared with the partner company)',
    terms_par_5_list_5: 'To partner event organization companies (in case you want to organize a party)',
    terms_par_5_list_6: 'To an external partner who supports us in software matters',
    terms_par_5_list_7: 'To the cooperating legal department of the company, in the event of a judicial or extrajudicial dispute',
    terms_par_5_list_8: 'To police or other government authorities if we are officially requested to do so or following a public prosecutors order',
    terms_heading_6: '5. Time interval of data storage',
    terms_paragraph_6: 'Your data is kept in the Duceroad database for 5 years after your last transaction with the company or for as long as the law may require (e.g. in the case of issuing an invoice, as long as the tax legislation defines). If the processing is based on your consent, the personal data is kept until you withdraw this consent. It is clarified that the withdrawal of consent does not affect the legality of the processing based on the consent before its withdrawal. In any case, Duceroad implements all appropriate technical and organizational measures to ensure the protection of the personal data it processes and constantly takes care to prevent any unauthorized access to this data.',
    terms_heading_7: '6. Rights you have in relation to your personal data',
    terms_paragraph_7: 'Regarding the processing of your personal data you can exercise the following rights:',
    terms_par_7_list_1_heading_1: 'Right of access',
    terms_par_7_list_2_paragraph_1: 'You have the right to know what data we hold and process about you, why and other additional information about it, as well as to ask us for a copy of it',
    terms_par_7_list_1_heading_2: 'Right of rectification',
    terms_par_7_list_2_paragraph_2: 'You have the right to request the correction, modification and completion of your personal data',
    terms_par_7_list_1_heading_3: 'Right to erasure ("right to be forgotten")',
    terms_par_7_list_2_paragraph_3: 'You have the right to request the deletion of your personal data when it is processed after your consent. In cases where the processing is based on another legal basis (such as the performance of a contract, a legal obligation or the protection of the Companys legal interests, etc.) your right may be subject to restrictions or may not exist',
    terms_par_7_list_1_heading_4: 'Right to restriction of processing',
    terms_par_7_list_2_heading_4: 'You have the right to request the restriction of the processing of your personal data',
    terms_par_7_list_2_heading_4_ul_1: 'When their accuracy is disputed and until the relevant verification is done alternatively, instead of deleting them',
    terms_par_7_list_2_heading_4_ul_2: 'When they are no longer necessary for us for the processing purposes for which we collected them, but are necessary for the establishment, exercise or support of legal claims by you',
    terms_par_7_list_2_heading_4_ul_3: 'When you object to their processing and until it is verified that there are legitimate reasons for this processing by the Company',
    terms_par_7_list_1_heading_5: 'Right to object to processing',
    terms_par_7_list_2_paragraph_5: 'You have the right to object to the processing of your personal data when it is based on a legitimate interest, as well as for direct marketing and profiling purposes',
    terms_par_7_list_1_heading_6: 'Right to portability',
    terms_par_7_list_2_paragraph_6: 'You have the right to request and receive your personal data in a form that allows you to access it, use it and process it with commonly used processing methods. In addition, for your data that we process by automated means and on the basis of your consent or for the performance of a contract, you can ask us to transfer it directly to another controller, as long as this is technically possible',
    terms_par_7_list_1_heading_7: 'Right to withdraw consent',
    terms_par_7_list_2_paragraph_7: 'If the processing of your data is based on your consent, you have the right to withdraw it at any time. The withdrawal of your consent does not affect the lawfulness of the processing that was based on the consent before its withdrawal. To exercise your above rights and for any question, complaint or other information regarding the processing of your personal data, you can contact us at the email info@duceroad.uk or at the contact details mentioned in paragraph 1.',
    terms_heading_8: '7. Complaint rights to the personal data protection authority (PDPA)',
    terms_paragraph_8: 'You have the right to file a complaint with the Personal Data Protection Authority (www.dpa.gr) regarding matters concerning the processing of your personal data',
    terms_heading_9: '8. Changes to the privacy policy',
    terms_paragraph_9: 'Any future changes to our Companys Privacy Policy will be posted on the www.duceroad.gr website and, where required, will be communicated to you.',
    terms_paragraph_pre_10: '"Duceroad" reserves the right to modify or renew unilaterally and without warning the present conditions according to its needs and the applicable laws. It is not obliged to inform the users of any modifications as well as any change, through the present website, but they must read the terms of use before taking any action.',
    terms_heading_10: 'Intellectual property rights',
    terms_paragraph_10: 'All content posted on our website including images, photos, texts and services provided are the intellectual property of "Duceroad" and its respective partners and are protected according to the relevant provisions of Greek law, European law and international conventions. Any copying, analog / digital recording and mechanical reproduction, distribution, transfer, processing, resale or misleading the public about the real provider of the content of the websites is prohibited. Any reproduction, republishing, uploading, communication, dissemination or transmission or any other use of the content in any manner or medium for commercial or other purposes is permitted only with prior written permission. The names, images, logos and distinguishing features that represent it are its exclusive marks and distinctive features and are protected by Greek, Community and international laws on trademarks and industrial and intellectual property and unfair competition. In any case, their appearance and exposure on the website should in no way be perceived as a transfer or assignment of a license or right to use them.',
}
