
<div class="header-outter">
  <div class="header" role="banner"> 
    
    <img id="logo"  width="230" alt="AppDate Logo" src="../assets/images/duceroad-logo-white.png" routerLink="/home" routerLinkActive="router-link-active"/>  
      
    <div class="spacer"></div>
    <div class="topbar-dropdown" appDropdown="topbar-dropdown--opened" #languageDropdown="appDropdown">
      <div class="topbar-dropdown__btn"  (click)="languageDropdown.toggle()">
        <img class="topbar__item-value" [src]="'assets/images/languages/'+(currentLanguage === 'EN' ? 'language-1' : 'language-2')+'.png'"
        [srcset]="'assets/images/languages/'+(currentLanguage === 'EN' ? 'language-1' : 'language-2')+'.png 1x,' +
        'assets/images/languages/'+(currentLanguage === 'EN' ? 'language-1' : 'language-2')" alt="">  
        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
      </div>
      <div class="topbar-dropdown__body">
        <div *ngFor="let language of languages" class="lang-menu">            
          <button  (click)="changeLang(language.code);languageDropdown.toggle()">            
            <img [src]="'assets/images/languages/'+language.image+'.png'"
            [srcset]="'assets/images/languages/'+language.image+'.png 1x,' +
            'assets/images/languages/'+language.image+'@2x.png 2x'" alt="">              
          <p>{{ language.name }}</p>
          </button>  
        </div>
      </div>
    </div>        

    <nav class="nav-bar" id="navigation-menu" >
      <ul class="nav-menu" [class.is-open]="isMenuOpen" [@hamburguerX]="isMenuOpen ? 'MenuOut' : 'MenuIn'">
      <li><a (click)="toggleMenu()" class="nav-link" aria-label="Home"  routerLink="/home" routerLinkActive="router-link-active" title="Home page" id="home">{{'nav_Link1' | translate : globals.getLanguage()}}</a></li>
      <li><a (click)="toggleMenu()" class="nav-link" aria-label="Apps"  routerLink="/apps" routerLinkActive="router-link-active" title="Apps" id="apps">{{'nav_Link2' | translate : globals.getLanguage()}}</a></li>
      <li><a (click)="toggleMenu()" class="nav-link" aria-label="Contact us"  routerLink="/contactus" routerLinkActive="router-link-active" title="Contact us" id="contact-us">{{'nav_Link3' | translate : globals.getLanguage()}}</a></li>
    </ul>
    </nav>

    <div class="hamburger" (click)="toggleMenu()">
      <div class="icon-bar" [@hamburguerX]="isMenuOpen ? 'topX' : 'hamburger'"></div>
      <div class="icon-bar" [@hamburguerX]="isMenuOpen ? 'hide' : 'hamburger'"></div>
      <div class="icon-bar" [@hamburguerX]="isMenuOpen ? 'bottomX' : 'hamburger'"></div>
        <!-- <p>{{ isMenuOpen ? "" : "MENU" }}</p> -->
    </div>

  </div>      
</div>

<div class="main-content">
  <router-outlet></router-outlet>
</div>

<section class="pre-footer"></section>

  <footer>

    <div class="footer-goal">
      <p>{{'our_goal' | translate : globals.getLanguage()}}</p>
    </div>

    <div class="footer-contact">
      <div class="logo-column">
        <img id="logo-footer"  width="200" alt="AppDate Logo" src="../assets/images/duceroad-logo-white.png" routerLink="/home" routerLinkActive="router-link-active"/>
      </div>
      <div class="divider-vert"></div>
      <div class="contact-column">
        <i class="material-icons">call</i>
        <div class="contact-info">
        <h4>{{'contact_input_4' | translate : globals.getLanguage()}}</h4>
        <a href="tel:0208mn1596097"><p>0208 159 6097</p></a>
      </div>
      </div>
      <div class="contact-column">
        <i class="material-icons">share_location</i>
        <div class="contact-info">
        <h4>{{'address' | translate : globals.getLanguage()}}</h4>
        <a href="https://goo.gl/maps/2jnB93mMrL6bUcbGA" target="_blank"><p>{{'addressof' | translate : globals.getLanguage()}}</p></a>
      </div>
      </div>
      <div class="contact-column">
        <i class="material-icons">mark_email_read</i>
        <div class="contact-info">
        <h4>Email</h4>
        <a href="mailto:info@duceroad.uk"><p>info@duceroad.uk</p></a>
      </div>
      </div>
    </div>

    <div class="copyright-outter">
    <div class="copyright">
        <div class="copyright-links">
          <p>© 2023 Duceroad.uk | All Rights Reserved</p>
          <a routerLink="/policy"  routerLinkActive="router-link-active" id="zero-margin-left">{{'Terms' | translate : globals.getLanguage()}}</a>
        </div>
        <div class="copyright-social">
          <a href="https://www.facebook.com/duceroad"  target="_blank"> <i class="material-icons">facebook</i></a>
        </div>
   </div>
  </div>

  <svg id="clouds" alt="Gray Clouds Background" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <path id="Path_39" data-name="Path 39" d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z" transform="translate(142.69 -634.312)" fill="#eee"/>
  </svg>

  </footer>





