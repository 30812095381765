<section > 
  <div class="header">
    <h1 class="display-2 text-white font-weight-bolder">
    Custom web and mobile applications</h1>
    <p class="text-gray display-4 mt-2">For all platforms. Super fast server.</p>  
  </div>
  <form ngNoForm class="email-container" onsubmit="return false;">            
    <input name="email" placeholder="Email address" required type="email" 
      value="" class="big-input" [(ngModel)]="email">
    <button mat-raised-button matSuffix class="rounded" type="submit" 
      color="warn" class="big-button" (click)="onSubmit()">
      Send
    </button>
  </form>
  <div class="img-container">
    <img   
    width="400"
    alt="Duceroad Logo"
    src="../../assets/images/codingW400.jpg"        
    />     
  </div>
  <div class="header mt-5">
    <h2 class="text-white font-weight-bolder display-3">
    Native code. Real time server</h2>
    <h3 class="text-gray mt-2 display-5">Build your own custom app for the AppStore or the PlayStore.</h3>  
  </div>
  <div class="super-row">
  <div class="space1"></div>
  <div class="mt-5 row">
    <div class="mb-3 col-lg-6">
      <div class="icon-box">
        <div class="d-flex px-3">
          <div class="icon-container">
            <div class="icon feature-icon">📝</div>
          </div>
          <div class="pl-4 wordind-container">
            <h5 class="icon-box-title text-white font-weight-bolder">Custom code</h5>
            <p class="icon-box-text text-light-gray">
              Custom coding in Javascript ES6, Angular11, React, React-Native, Swift, Kotlin.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 col-lg-6">
      <div class="icon-box"><div class="d-flex px-3">
        <div>
          <div class="icon feature-icon">☁️</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">Deploy in personal cloud</h5>
          <p class="icon-box-text text-light-gray">Deploy the app in your private server.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon">⚡</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">Ultra fast Javascript server</h5>
          <p class="icon-box-text text-light-gray">Nodejs, Expressjs, Feathersjs real time Javascript web server.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon">💽</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">Ultra fast database</h5>
          <p class="icon-box-text text-light-gray">NoSQL Databases like MongoDB are used.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon">📊</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">User friendly Dashboard</h5>
          <p class="icon-box-text text-light-gray">Administrative Dashboard with charts and analytics.</p>
        </div>
      </div>
    </div>
  </div>  
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">🔒</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">Enhanced security</h5>
          <p class="icon-box-text text-light-gray">HTTPS, encrypted passwords, JWT, reverse proxy.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">💶</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">Low prices</h5>
          <p class="icon-box-text text-light-gray"> Project pricing starting at € 5.000. Or you can hire us with a monthly fee.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 col-lg-6">
    <div class="icon-box">
      <div class="d-flex px-3">
        <div>
          <div class="icon icon-primary feature-icon mb-5">💳</div>
        </div>
        <div class="pl-4">
          <h5 class="icon-box-title text-white font-weight-bolder">Pay as you go</h5>
          <p class="icon-box-text text-light-gray">Pay according to the project progress per week or per month.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="space1"></div>
</div>
<div class="header mt-5">
  <h2 class="text-white font-weight-bolder display-3">
    {{'presentation' | translate : globals.getLanguage()}}</h2>
  <h3 class="text-gray mt-2 display-5">{{'presDetail' | translate : globals.getLanguage()}}</h3>  
</div>
<form ngNoForm class="email-container" onsubmit="return false;">            
  <input name="email" placeholder="Email address" required type="email" 
    value="" class="big-input" [(ngModel)]="email">
  <button mat-raised-button matSuffix class="rounded" type="submit" 
    color="warn" class="big-button" (click)="onSubmit()">
    Send
  </button>
</form>
</section>
