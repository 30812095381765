<div class="container-outter">
  <div class="container">
      <div class="heading-section">
          <div class="heading-title">
            <h1 class="font-darkblue">{{'eshop_Heading_Title' | translate : globals.getLanguage()}}</h1>
            <p class="font-grey">{{'eshop_Heading_Paragraph' | translate : globals.getLanguage()}}</p>
              <div class="aside-divider">
                <div class="divider"></div>
                  <p class="font-grey">{{'eshop_Heading_Paragraph_2' | translate : globals.getLanguage()}}</p>
                <div class="divider"></div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="container-outter">
<div class="container">
  <div class="container-inner">
    <div class="column col-1">
      <div class="features-content">
        <h3><span class="font-lightblue">01. </span>{{'eshop_Title_1' | translate : globals.getLanguage()}}</h3>
        <div class="left-divider-sm"></div>
        <ul class="features-list">
          <li>
            <h4>{{'eshop_Heading_Paragraph_1_1' | translate : globals.getLanguage()}}</h4>
            <p>{{'eshop_Paragraph_1_1' | translate : globals.getLanguage()}}</p>
          </li>
          <li>
            <h4>{{'eshop_Heading_Paragraph_1_2' | translate : globals.getLanguage()}}</h4>
            <p>{{'eshop_Paragraph_1_2' | translate : globals.getLanguage()}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="column col-2">
      <div class="feature-img features-image-right">
        <img alt="eshop-woocommerce-img-1" src="../../assets/images/eshop-making-graph1.png"/>
      </div>
    </div>
  </div>
</div>
</div>

<div class="container-outter">
  <div class="container">
    <div class="container-inner">
      <div class="column col-2">
        <div class="feature-img features-image-left">
          <img alt="kataskevi-eshop-safe-woocommerce-payments" src="../../assets/images/eshop-making-graph2-safe-payments.png"/>
        </div>
      </div>
      <div class="column col-1">
        <div class="features-content">
          <h3><span class="font-lightblue">01. </span>{{'eshop_Title_2' | translate : globals.getLanguage()}}</h3>
          <div class="left-divider-sm"></div>
          <ul class="features-list">
            <li>
              <h4>{{'eshop_Heading_Paragraph_2_1' | translate : globals.getLanguage()}}</h4>
              <p>{{'eshop_Paragraph_2_1' | translate : globals.getLanguage()}}</p>
            </li>
            <li>
              <h4>{{'eshop_Heading_Paragraph_2_2' | translate : globals.getLanguage()}}</h4>
              <p>{{'eshop_Paragraph_2_2' | translate : globals.getLanguage()}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="container-outter">
    <div class="container">
      <div class="container-inner">
        <div class="column col-1">
          <div class="features-content">
            <h3><span class="font-lightblue">02. </span>{{'eshop_Title_3' | translate : globals.getLanguage()}}</h3>
            <div class="left-divider-sm"></div>
            <ul class="features-list">
              <li>
                <h4>{{'eshop_Heading_Paragraph_3_1' | translate : globals.getLanguage()}}</h4>
                <p>{{'eshop_Paragraph_3_1' | translate : globals.getLanguage()}}</p>
              </li>
              <li>
                <h4>{{'eshop_Heading_Paragraph_3_2' | translate : globals.getLanguage()}}</h4>
                <p>{{'eshop_Paragraph_3_2' | translate : globals.getLanguage()}}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="column col-2">
          <div class="feature-img features-image-right">
            <img alt="kataskevi-istoselidas-eshop-product-upload" src="../../assets/images/products-upload-woocommerce-kataskevi.png"/>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div class="container-outter">
      <div class="container">
        <div class="container-inner">
          <div class="column col-2">
            <div class="feature-img features-image-left">
              <img alt="app-img" src="../../assets/images/flexible-shipping-woocommerce.png"/>
            </div>
          </div>
          <div class="column col-1">
            <div class="features-content">
              <h3><span class="font-lightblue">03. </span>{{'eshop_Title_4' | translate : globals.getLanguage()}}</h3>
              <div class="left-divider-sm"></div>
              <ul class="features-list">
                <li>
                  <h4>{{'eshop_Heading_Paragraph_4_1' | translate : globals.getLanguage()}}</h4>
                  <p>{{'eshop_Paragraph_4_1' | translate : globals.getLanguage()}}</p>
                </li>
                <li>
                  <h4>{{'eshop_Heading_Paragraph_4_2' | translate : globals.getLanguage()}}</h4>
                  <p>{{'eshop_Paragraph_4_2' | translate : globals.getLanguage()}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div class="container-outter">
        <div class="container">
          <div class="container-inner">
            <div class="column col-1">
              <div class="features-content">
                <h3><span class="font-lightblue">04. </span>{{'eshop_Title_6' | translate : globals.getLanguage()}}</h3>
                <div class="left-divider-sm"></div>
                <ul class="features-list">
                  <li>
                    <h4>{{'eshop_Heading_Paragraph_6_1' | translate : globals.getLanguage()}}</h4>
                    <p>{{'eshop_Paragraph_6_1' | translate : globals.getLanguage()}}</p>
                  </li>
                  <li>
                    <h4>{{'eshop_Heading_Paragraph_6_2' | translate : globals.getLanguage()}}</h4>
                    <p>{{'eshop_Paragraph_6_2' | translate : globals.getLanguage()}}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="column col-2">
              <div class="feature-img features-image-right">
                <img alt="eshop-woocommerce-img-1" src="../../assets/images/eshop-making-graph1.png"/>
              </div>
            </div>
          </div>
        </div>
        </div>
    
      <div class="container-outter">
        <div class="container">
          <div class="container-inner">
            <div class="column col-2">
              <div class="feature-img features-image-left">
                <img alt="app-img" src="../../assets/images/skroutz-bestprice-eshop.png"/>
              </div>
            </div>
            <div class="column col-1">
              <div class="features-content">
                <h3><span class="font-lightblue">05. </span>{{'eshop_Title_5' | translate : globals.getLanguage()}}</h3>
                <div class="left-divider-sm"></div>
                <ul class="features-list">
                  <li>
                    <h4>{{'eshop_Heading_Paragraph_5_1' | translate : globals.getLanguage()}}</h4>
                    <p>{{'eshop_Paragraph_5_1' | translate : globals.getLanguage()}}</p>
                  </li>
                  <li>
                    <h4>{{'eshop_Heading_Paragraph_5_2' | translate : globals.getLanguage()}}</h4>
                    <p>{{'eshop_Paragraph_5_2' | translate : globals.getLanguage()}}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>


<div class="container-outter project-section">
  <div class="container">
    <div class="space-bottom">

      <div class="project-heading-section">
        <div class="heading-title">
          <div class="project-title">
            <h2>{{'eshop_Project_Heading' | translate : globals.getLanguage()}}</h2>
            <p>{{'eshop_Project_Paragraph' | translate : globals.getLanguage()}}</p>
          </div>
        </div>
      </div>

      <div class="column-container">

        <div class="column-box">
          <div class="project-wrapper">
            <a href="https://angelozafiri.gr/" target="_blank" class="white-box">
                <img class="portfolio-grid-img" src="/assets/images/Angelo-Zafiri.gr.jpg" alt="AngeloZafiri.gr">
                <div class="portfolio-box-content">
                  <h4>AngeloZafiri.gr</h4>
                </div>
            </a>
            <img src="/assets/images/gray-square.png" class="square-topleft"/>
          </div>
        </div>

          <div class="column-box">
            <div class="project-wrapper">
              <a href="https://perfectsleep.gr" target="_blank" class="white-box">
                  <img class="portfolio-grid-img" src="/assets/images/perfect-sleep.gr.jpg" alt="PerfectSleep.gr">
                  <div class="portfolio-box-content">
                    <h4>Perfectsleep.gr</h4>
                  </div>
              </a>
            </div>
          </div>

          <div class="column-box">
            <div class="project-wrapper">
              <a href="https://inahome.gr" target="_blank" class="white-box">
                  <img class="portfolio-grid-img" src="/assets/images/inahome.gr.jpg" alt="InaHome.gr">
                  <div class="portfolio-box-content">
                    <h4>Inahome.gr</h4>
                  </div>
              </a>
            </div>
          </div>

            <div class="column-box">
              <div class="project-wrapper">
                <a href="https://marinaeshop.gr" target="_blank" class="white-box">
                    <img class="portfolio-grid-img" src="/assets/images/marinaeshop.gr.jpg" alt="Marinaeshop.gr">
                    <div class="portfolio-box-content">
                      <h4>Marinaeshop.gr</h4>
                    </div>
                </a>
                <img src="/assets/images/gray-square.png" class="square-botright"/>
              </div>
            </div>

        </div>

        <div class="flex-center">
          <a routerLink="/contactus" routerLinkActive="router-link-active" class="btn project-btn base-transition">{{'eshop_Project_Button' | translate : globals.getLanguage()}}</a>
        </div>
    </div>
  </div>
</div>

<!-- <div class="container-outter">
  <div class="container">
    <div class="section-title">
      <h1 class="font-darkblue">{{'eshop_section_Title' | translate : globals.getLanguage()}}</h1>
      <p class="font-grey first-p">{{'eshop_section_Paragraph_1' | translate : globals.getLanguage()}}</p>
      <p class="font-grey">{{'eshop_section_Paragraph_2' | translate : globals.getLanguage()}}</p>
    </div>
    <div class="spacer space-bottom space-top">
      <mat-tab-group class="centered-tabs" mat-align-tabs="center">
        <mat-tab label="ESHOP">
          
        </mat-tab>
        <mat-tab label="ΦΙΛΟΞΕΝΙΑ">Content 2</mat-tab>
        <mat-tab label="ΥΠΟΣΤΗΡΙΞΗ">Content 3</mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div> -->


