<section class="spotlight">
  <div class="spotlight-text">
    <h1>Custom apps</h1>
  </div>
</section>



<div class="content" role="main">
  <div class="work">
    <h2>{{'home_title' | translate : globals.getLanguage()}}</h2>
    <p>{{'home_description' | translate : globals.getLanguage()}}</p>
  <div class="work-content">
    <h2>{{'home_products' | translate : globals.getLanguage()}}</h2>
    <div class="card-container">
      <a routerLink="/eshop"> 
        <div class="card" id="eshop" ><p>Standard e-shop</p></div>          
        <div class="caption-container">
          <span>Standard e-shop</span>
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg> 
        </div>
      </a>
      <a routerLink="/intheshop"> 
        <div class="card" id="intheshop" ><p>Custom e-shop</p></div>          
        <div class="caption-container">
          <span>Custom e-shop</span>
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg> 
        </div>
      </a>
      <a  routerLink="/food">  
        <div class="card" id="delivery"><p>Food Delivery</p></div>
        <div class="caption-container">
          <span>BringFood</span>
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
        </div>
      </a>
      <a  routerLink="/booking-system">  
        <div class="card" id="booking"><p>Hotel Booking System</p></div>
        <div class="caption-container">
          <span>Hotel Booking System</span>
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
        </div>
      </a>
      <a  routerLink="/mobile">        
        <div class="card" id="mobile"><p>Custom Apps</p></div>
        <div class="caption-container">
          <span>Custom Apps</span>
          <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
        </div>
      </a>

  <!-- Next Steps -->
  <div class="sub-header">
    <h2>{{'home_steps' | translate : globals.getLanguage()}}</h2>
    <p>{{'home_teazer' | translate : globals.getLanguage()}}</p>
  </div>
  <div class="card-container2"> 
      <a href="tel:02081596097">
        <div class="card card-small" id="callus">      
          <i class="material-icons">call</i>
          <span>{{'home_call' | translate : globals.getLanguage()}}</span>
        </div>
      </a>
      <a routerLink="/contactus">
        <div class="card card-small"  >
          <i class="material-icons">alternate_email</i>
          <span>{{'home_contact' | translate : globals.getLanguage()}}</span>
        </div>
      </a>
  </div>
</div>

</div>
</div>  
</div>